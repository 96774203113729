import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { FormFieldAssistiveLine } from '@ast/magma/components/formfield/formfieldassistive/formfieldassistive';

import { ReCaptchaVersionCodes } from '@app/queryTyping';

import { useGetRecaptchaSettings } from '@app/common/components/ReCaptcha/useGetRecaptchaSettings';
import { useRecaptchaV3 } from '@app/common/components/ReCaptcha/useReCaptchaV3';
import {
  NotFieldContentRenderer, WizardStep, WizardStepProps, WizardStepSubmitHandler,
} from '@app/common/configurable-wizards';

import { RECAPTCHA_TOKEN_KEY, RECAPTCHA_VERSION_KEY } from '@app/core/components/ReCaptcha/reCaptchaApolloLink';
import { useColorThemeContext } from '@app/core/contexts/colorTheme/ColorThemeContext';
import { setSessionValue } from '@app/core/storage/browser/sessionStorage';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { useReCaptchaValidationException } from './useReCaptchaValidationException';
import styles from './WizardStepWithReCaptcha.pcss';

export const RECAPTCHA_EXECUTE_ACTION = 'login_submit';

export const WizardStepWithReCaptcha: React.FC<WizardStepProps> = (props) => {
  const { t } = useUserLoginTranslation();
  const { colorTheme } = useColorThemeContext();
  const submitFormRef = useRef<(contextData?: Record<string, unknown>) => void>();
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const recaptchaSettings = useGetRecaptchaSettings();
  const recaptchaValidationException = useReCaptchaValidationException();
  const showRecaptchaChallenge = !!recaptchaValidationException;
  const [recaptchaChallengePassed, setRecaptchaChallengePassed] = useState<boolean>();

  useEffect(() => {
    if (recaptchaValidationException?.version === ReCaptchaVersionCodes.VERSION_2) {
      // if recaptcha server validation fails, reset the recaptcha
      recaptchaRef.current?.reset();

      // then set the challenge passed to false
      setRecaptchaChallengePassed(false);
    }
  }, [recaptchaValidationException]);

  const { executeRecaptcha: executeV3Recaptcha, waitForRecaptcha } = useRecaptchaV3({
    siteKey: recaptchaSettings.siteKeyV3,
    bypass: !recaptchaSettings.isEnabled || showRecaptchaChallenge,
  });

  const onRecapthcaVerified = useCallback((token: string | null) => {
    setSessionValue(RECAPTCHA_TOKEN_KEY, token || '');
    setSessionValue(RECAPTCHA_VERSION_KEY, ReCaptchaVersionCodes.VERSION_2);
    setRecaptchaChallengePassed(true);
    // auto submit the form after recaptcha is passed
    submitFormRef.current?.();
  }, []);

  const onRecapthcaExpired = useCallback(() => {
    setSessionValue(RECAPTCHA_TOKEN_KEY, '');
    setRecaptchaChallengePassed(false);
  }, []);

  const onSubmit: WizardStepSubmitHandler = useCallback(async (form) => {
    if (recaptchaSettings?.isEnabled) {
      // v3 recaptcha
      if (!showRecaptchaChallenge) {
        await waitForRecaptcha();

        const tokenV3 = await executeV3Recaptcha(RECAPTCHA_EXECUTE_ACTION);
        setSessionValue(RECAPTCHA_TOKEN_KEY, tokenV3 || '');
        setSessionValue(RECAPTCHA_VERSION_KEY, ReCaptchaVersionCodes.VERSION_3);
        props.onSubmit(form);
      } else if (recaptchaChallengePassed) {
        props.onSubmit(form);
      }
    } else { // v2 recaptcha
      props.onSubmit(form);
    }
  }, [
    recaptchaSettings?.isEnabled,
    showRecaptchaChallenge,
    recaptchaChallengePassed,
    executeV3Recaptcha,
    // eslint-disable-next-line react/destructuring-assignment
    props.onSubmit,
  ]);

  const afterFieldsContent: NotFieldContentRenderer = (formState) => (
    recaptchaSettings?.isEnabled
      ? (
        <div className={styles.recaptchaContainer}>
          {showRecaptchaChallenge && (
            <ReCAPTCHA
              sitekey={recaptchaSettings.siteKeyV2!}
              ref={recaptchaRef}
              onChange={onRecapthcaVerified}
              onExpired={onRecapthcaExpired}
              theme={colorTheme === 'dark' ? 'dark' : 'light'}
              data-stable-name="ReCaptcha"
            />
          )}
          {showRecaptchaChallenge && !recaptchaChallengePassed && (
            <FormFieldAssistiveLine
              error={t(
                'login.steps.username-password.recapctha.validation.message|Sign in button text',
                'Please verify that you are not a robot',
              )}
              className={styles.recaptchaError}
              data-stable-name="ReCaptchaError"
            />
          )}

          {/* Include passed content */}
          {props.afterFieldsContent && props.afterFieldsContent(formState)}
        </div>
      )
      : null
  );

  return (
    <WizardStep
      {...props}
      buttons={(buttonsProp) => {
        // keep a reference to the submit fn for auto submiting the form after recaptcha is passed
        submitFormRef.current = buttonsProp.submit;
        return props.buttons ? props.buttons(buttonsProp) : null;
      }}
      onSubmit={(form) => onSubmit(form)}
      afterFieldsContent={afterFieldsContent}
    />
  );
};
