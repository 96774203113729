import { Tracing } from '@ast/opentelemetry';

import { appSettings } from '@app/core/appSettings';
import { removeCorrSessionId } from '@app/core/authentication/customAuthHeaders/corrSessionId';

export {
  useSetTraceSpan, X_AMAZON_TRACE_ID_KEY, context, Span, trace,
} from '@ast/opentelemetry';

const otlpCollectorEnabled = Boolean(appSettings().otlpCollectorEnabled === 'true') && appSettings().otlpCollectorUrl;

// eslint-disable-next-line no-nested-ternary
const samplingRatio = localStorage.getItem('OTLP-SAMPLING-RATIO')
  ? Number(localStorage.getItem('OTLP-SAMPLING-RATIO'))
  : (appSettings().otlpSamplingRatio ? Number(appSettings().otlpSamplingRatio) : 0.07);

const exportMetricsIntervalMillis = appSettings().otlpExportIntervalMillis
  ? Number(appSettings().otlpExportIntervalMillis)
  : 60000;

const exporterUrl = otlpCollectorEnabled ? appSettings().otlpCollectorUrl : '';

export const getTracingOptions = () => ({
  serviceName: 'ofm-fe',
  isDebugEnabled: false,
  samplingRatio,
  exporterUrl,
  exportMetricsIntervalMillis,
  metricConcurrencyLimit: 10,
  resourceAttrs: {
    'fi.name': appSettings().otlpOrgName,
    'deployment.environment': appSettings().otlpDeploymentEnvironment,
  },
});

export const tracing = new Tracing(getTracingOptions());
export const parseAmazonTraceId = (str: string) => ({
  traceId: str.slice(7, 40).replace(/-/, ''),
  spanId: str.slice(48, 64),
});
