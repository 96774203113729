import { getRecaptchaScript } from './getRecaptchaScript';

export const loadRecaptchaScript = (
  siteKey?: string | undefined,
  bypass?: boolean,
  onRecaptchaLoad?: () => void,
) => {
  let script: HTMLScriptElement | null = null;

  if (!bypass && siteKey) {
    script = getRecaptchaScript(siteKey);
    script.onload = () => {
      if (onRecaptchaLoad) {
        onRecaptchaLoad();
      }
    };
    document.body.appendChild(script);
  }

  return () => {
    if (script) {
      document.body.removeChild(script);
    }
  };
};
