/* eslint-disable i18next/no-literal-string */
import { useEffect, useRef, MutableRefObject } from 'react';

import { ApolloError, isApolloError, QueryFunctionOptions } from '@apollo/client';

import { VerifyAuthenticationParams } from '@app/queryTyping';

import { setWizardStepAlertMessage, setWizardStepLoading } from '@app/common/configurable-wizards';
import { useWizardStepStateDispatchContext } from '@app/common/configurable-wizards/WizardStateDispatchContext';
import { getUserErrors } from '@app/common/errors/getGraphQLUserError';
import { logPasskeyDebug, logPasskeyError } from '@app/common/utils/logPasskey';

import { client } from '@app/core/apolloClient';
import { PageURL } from '@app/core/widgets/pages';

import {
  convertGetOptions,
  convertGetCredential,
} from '@app/widgets/contacts-and-settings/fields/ProfileAndSecurity/Passkeys/webauthn-helpers';
import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { WizardSteps } from '../../constants';
import { GeneratePasskeyAuthOptionsDocument, GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables } from './queries/queryTyping/generate-passkey-auth-options';
import { useVerifyPasskeyAuthenticationMutation } from './queries/queryTyping/verify-passkey-authentication';

import { checkMediationAvailable } from './utils';

const buildVerificationVariables = (publicKeyCredential: Credential) : {
  variables: { verifyAuthenticationParams: VerifyAuthenticationParams } } => ({
  variables: {
    verifyAuthenticationParams: convertGetCredential(publicKeyCredential),
  },
});

export const usePasskeyLogin = () => {
  const dispatch = useWizardStepStateDispatchContext();

  const onApolloError: QueryFunctionOptions['onError'] = (wizardError) => {
    dispatch(setWizardStepLoading(false));

    const displayErrors = getUserErrors(wizardError);
    if (displayErrors) {
      const urlParams = new URLSearchParams({
        // eslint-disable-next-line i18next/no-literal-string
        isSuccess: 'false',
        message: encodeURIComponent(displayErrors.join()),
      });
      const { location } = window;

      location.replace(
        // eslint-disable-next-line max-len
        `${window.location.origin}${PageURL.USER_LOGIN}/${WizardSteps.LoginResult}?${urlParams.toString()}`,
      );
    }
  };

  const { t } = useUserLoginTranslation();
  const abortControllerRef: MutableRefObject<AbortController | null> = useRef(null);

  const [verifyAuthentication] = useVerifyPasskeyAuthenticationMutation({
    onError: (error) => {
      if (isApolloError(error)) {
        onApolloError(error);
      }
      throw error;
    },
    onCompleted: (data) => {
      if (!data.verifyAuthentication!.success) {
        throw new Error(data.verifyAuthentication!.message);
      }
    },
  });

  const authenticate = async (isAuthConditional: boolean) => {
    let userIsEngaged = false;
    if (isAuthConditional) {
      const mediationAvailable = await checkMediationAvailable();

      // if browser does not support conditional flow for passkey auth - do nothing
      if (!mediationAvailable) {
        return;
      }
    }

    if (abortControllerRef.current) {
      // @ts-ignore
      abortControllerRef.current.abort({ message: 'Aborting previous passkey authentication request', name: 'AbortError' });
    }

    abortControllerRef.current = new AbortController();
    const abortSignal = abortControllerRef.current.signal;

    // eslint-disable-next-line max-len
    const { data: passkeyOptions, error } = await client.query<GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables>({
      query: GeneratePasskeyAuthOptionsDocument,
      fetchPolicy: 'no-cache',
    });
    if (error) {
      onApolloError(error);
      throw error;
    }

    if (passkeyOptions) {
      try {
        const publicKey = convertGetOptions(passkeyOptions.generateAuthOptions);
        logPasskeyDebug('publicKey', publicKey);

        const publicKeyCredential = await navigator.credentials.get({
          publicKey: publicKey as PublicKeyCredentialRequestOptions,
          // @ts-ignore
          mediation: isAuthConditional ? 'conditional' : 'required', // Default 'required' if not conditional
          signal: abortSignal,
        }) as PublicKeyCredential | null;
        logPasskeyDebug('publicKeyCredential', publicKeyCredential);

        userIsEngaged = true;

        if (publicKeyCredential) {
          // when user selects passkey for login
          // we need to show loader
          if (isAuthConditional) {
            dispatch(setWizardStepLoading(true));
          }

          const verificationVariables = buildVerificationVariables(publicKeyCredential);
          logPasskeyDebug('verificationVariables', verificationVariables);

          await verifyAuthentication(verificationVariables);
          logPasskeyDebug('success', 'Passkey authentication success');
        }
      } catch (err: any) {
        logPasskeyError(err, `passkey login error: ${err?.message}`);

        if (!userIsEngaged && isAuthConditional) {
          // user was not engaged in the process, it is some internal error.
          // We should not display it to the user.
          logPasskeyDebug(`internalError ${err.message}`, err);

          throw err;
        }
        if (err.name === 'AbortError') {
          logPasskeyDebug(`AbortError ${err.message}`, err);

          // Authentication aborted. Throw error to be caught and not to contiue login process;
          throw err;
        } if (!(err instanceof ApolloError)) {
          logPasskeyDebug(`passkeyError ${err.message}`, err);

          dispatch(setWizardStepAlertMessage({
            content: t('login.passkey.error.message|Login passkey error message', 'An error occurred while trying to authenticate with a passkey'),
            type: 'error',
          }));
        }

        throw err;
      }
    }
  };

  useEffect(() => () => {
    // when component unmounted, we need to abort conditional request, if it was not aborted previously
    if (abortControllerRef.current) {
      // @ts-ignore
      abortControllerRef.current.abort({ message: 'Component unmounted, aborting request.', name: 'AbortError' });
    }
  },
  []);

  return {
    startPasskeyAuth: () => authenticate(false),
    startPasskeyConditionalAuth: () => authenticate(true),
  };
};
