import { useEffect, useMemo, useRef } from 'react';

import { loadRecaptchaScript } from './utils/loadRecaptchaScript';

import './useReCaptchaV3.css';

export interface UseRecaptchaV3Props {
  readonly siteKey?: string;
  readonly bypass?: boolean;
}

export type UseRecaptchaV3Return = {
  executeRecaptcha: (action: string) => Promise<string>;
  waitForRecaptcha: () => Promise<boolean>;
};

export const useRecaptchaV3 = ({ siteKey, bypass }: UseRecaptchaV3Props) => {
  const loaded = useRef<boolean>();

  const captchaInterval = useRef<number | null>(null);

  const onRecaptchaLoad = () => {
    loaded.current = true;
  };

  useEffect(() => {
    loadRecaptchaScript(siteKey, bypass, onRecaptchaLoad);
    return () => {
      if (captchaInterval.current) {
        clearInterval(captchaInterval.current);
      }
    };
  }, [siteKey, bypass]);

  const execute = (action: string, resolve: (value: string | PromiseLike<string>) => void) => {
    window.grecaptcha.ready(() => {
      // @ts-ignore
      window.grecaptcha.execute(siteKey, { action }).then(resolve);
    });
  };

  const executeRecaptcha = useMemo<(action: string) => Promise<string>>(
    () => (action: string) => new Promise((resolve, reject) => {
      try {
        if (!loaded.current) {
          reject(new Error('Recaptcha is not ready'));
          return;
        }

        // @ts-ignore
        if (window.grecaptcha) {
          execute(action, resolve);
        }
      } catch (e) {
        reject(e);
      }
    }), [loaded, siteKey]);

  const waitForRecaptcha = () => new Promise<boolean>((resolve) => {
    if (loaded.current) {
      resolve(true);
    } else {
      // @ts-ignore
      captchaInterval.current = setInterval(() => {
        if (loaded && loaded.current) {
          if (captchaInterval.current) {
            clearInterval(captchaInterval.current);
            captchaInterval.current = null;
          }
          resolve(true);
        }
      }, 100);
    }
  });

  return { executeRecaptcha, waitForRecaptcha };
};
